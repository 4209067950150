<template>
    <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
    <v-text-field
    v-model="form.primaryContactNumber"
      label="Primary Telephone Number"
      prepend-icon="mdi-phone"
      disabled
    ></v-text-field>
    <v-text-field
      v-model="form.primaryContact"
      label="Primary Contact"
      prepend-icon="mdi-phone"
      disabled
    ></v-text-field>
    <v-text-field
      v-model="form.email"
      label="Primary Email"
      prepend-icon="mdi-email"
      disabled
    ></v-text-field>
    <v-select
    v-model="selectedContact"
    item-text="value"
      item-value="id"
      :items="form.changeContact"
      prepend-icon="mdi-account-box"
      label="Change Primary Contact"
    ></v-select>

    <v-row class="mt-4 " justify="end">
      <v-col cols="6">
        <v-btn
          depressed
          color="blue"
          @click="validate"
          block
          dark
          large
        >
          Save changes
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  data(){
    return{
      form: {
        primaryContactNumber: 'Null',
        primaryContact: 'Mr Nicky Fallows',
        email: 'nickyfallows@gmail.com',
        website: 'Null',
        changeContact: [
          'Mr Nicky Fallows',
          'Test 1',
          'Mr Test jones',
          'Mr Lee Hadfield'
        ]
      },
      selectedContact: 'Mr Nicky Fallows'
    }
  }
}
</script>
